export default {
    SET_MODAL_STATUS: 'SET_MODAL_STATUS',
    SET_UPLOAD_PROGRESS: 'SET_UPLOAD_PROGRESS',
    SET_SELECTED_MICROPHONE: 'SET_SELECTED_MICROPHONE',
    TOGGLE_SHOW_MODAL: 'TOGGLE_SHOW_MODAL',
    COUNTDOWN_TICK: 'COUNTDOWN_TICK',
    RESET_COUNTDOWN_TIMER: 'RESET_COUNTDOWN_TIMER',
    SUBMIT_TESTER_EMAIL: 'SUBMIT_TESTER_EMAIL',
    SET_APP_ID: 'SET_APP_ID',
    SET_TEST_DETAILS: 'SET_TEST_DETAILS',
}
