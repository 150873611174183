import React, {Component} from 'react'
import WaveSurfer from 'wavesurfer.js'
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.min'


class Audiowave extends Component {
    componentDidMount() {
        const {microphoneId} = this.props

        this.wavesurfer = WaveSurfer.create({
            container: '#waveform',
            waveColor: 'black',
            height: 48,
            interact: false,
            cursorWidth: 0,
            plugins: [
                MicrophonePlugin.create({
                    constraints: {
                        audio: {
                            deviceId: microphoneId
                        }
                    }
                })
            ]
        })

        this.wavesurfer.microphone.start()
    }

    componentWillUnmount() {
        this.wavesurfer.microphone.stop()
        this.wavesurfer.destroy()
    }

    render() {
        return <div id="waveform"/>
    }
}

export default Audiowave