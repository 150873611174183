import React, {Component} from 'react'
import Button from '../ui/Button'
import {t} from '../../services/i18n'
import {connect} from 'react-redux'
import actions from '../../actions'

class EmailForm extends Component {
    state = {
        email: '',
    }

    submitEmail = e => {
        e.preventDefault()
        this.props.dispatch(actions.submitEmail(this.state.email))
    }

    render() {
        const {email} = this.state

        return <form className="confirm-email-block" onSubmit={this.submitEmail}>
            <p>{t('textEmailAudioFeedback')}</p>
            <input type="email" value={email} onChange={e => this.setState({email: e.target.value})}/>
            <Button success>{t('buttonSendAudioFeedback')}</Button>
            <p className="email-notice">{t('textLegalEmailAudioFeedback')}</p>
        </form>
    }
}

export default connect()(EmailForm)