import request from 'superagent'
import config from '../config'

const getURI = path => config.apiHost + path

async function post({path, body, clientId, complete = false}) {
    const req = request
        .post(getURI(path))
        .set('clientId', clientId)
        .send(body)

    if (complete) {
        return req
    }

    return req.then(req => req.body)
}

async function patch({path, body, clientId, complete = false}) {
    const req = request
        .patch(getURI(path))
        .set('clientId', clientId)
        .send(body)

    if (complete) {
        return req
    }

    return req.then(req => req.body)
}


async function getUploadURL({clientId}) {
    return post({
        path: '/sdk/test',
        clientId,
    })
}

async function uploadBlob({onProgress, uploadURL, onError, onFinished, blob, contentType = 'audio/wav'}) {
    return request
        .put(uploadURL)
        .set('Content-Type', contentType)
        .send(blob)
        .on('progress', onProgress)
        .on('error', error => {
            console.error('Error uploading file', error)
            window.alert('There was an error uploading your file. Please try again. ' + error)
            onError(error)
            throw error
        })
        .end(onFinished)
}

async function submitTesterEmail({email, clientId, testExternalId}) {
    return patch({
        clientId,
        path: `/sdk/test/${testExternalId}`,
        body: {
            testerEmail: email,
        }
    })
}


export {
    uploadBlob,
    getUploadURL,
    submitTesterEmail,
}