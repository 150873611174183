import React, {Component, Fragment} from 'react'
import Recorder from 'recorder-js'
import {uploadBlob} from '../../services/api'
import {connect} from 'react-redux'
import actionTypes from '../../actions/types'
import actions from '../../actions'
import Audiowave from './Audiowave'
import MicrophoneSelector from './MicrophoneSelector'
import EmailForm from './EmailForm'
import {t} from '../../services/i18n'

const audioContext = new (window.AudioContext || window.webkitAudioContext)();
const recorder = new Recorder(audioContext, {})

const formatCountdown = totalSeconds => {
    const minutes = parseInt(totalSeconds / 60)
    const seconds = parseInt(totalSeconds % 60)

    const formattedSeconds = seconds > 9 ? seconds : `0${seconds}`

    return `${minutes}:${formattedSeconds}`
}

const ModalContainer = ({children}) => {
    return <Fragment>
        <div className="brutal-panel">
            {children}
            <div className="powered-brutal"/>
        </div>
    </Fragment>
}


class Modal extends Component {
    blob = null

    startRecording = async () => {
        const {stream, dispatch} = this.props
        recorder.init(stream)
        await recorder.start()
        dispatch(actions.startRecording({onRecordingCountdown: this.stopRecording}))
    }

    stopRecording = async () => {
        const {dispatch, stopStream} = this.props
        const {blob} = await recorder.stop()
        dispatch(actions.stopRecording)
        dispatch(actions.uploadBlob({blob}))
        const url = URL.createObjectURL(blob)
        stopStream()
    }

    MainButton = ({showEmailForm}) => {
        const {modalStatus, modalStatusType, uploadProgress} = this.props
        if (modalStatus === modalStatusType.recording) {
            return <a className="btn-stop-audio-recording" onClick={this.stopRecording}/>
        } else if (modalStatus === modalStatusType.idle) {
            return <a className="btn-start-audio-recording" onClick={this.startRecording}/>
        } else if (modalStatus === modalStatusType.uploading) {
            const text = uploadProgress >= 100 ? t('statusUploadedAudioFeedback') : `${uploadProgress}%`
            return <a className="upload-status">{text}</a>
        } else {
            return <a className="btn-start-audio-recording">Default Button</a>
        }
    }

    render() {
        const {
            showModal, stream, remainingRecordingTime, emailSubmitted, uploadProgress,
            modalStatus, modalStatusType, selectedMicrophoneId,
        } = this.props
        const {MainButton} = this

        const isRecording = modalStatus === modalStatusType.recording
        const isUploaded = uploadProgress >= 100
        const isUploading = modalStatus === modalStatusType.uploading && !isUploaded
        const isIdle = modalStatus === modalStatusType.idle
        const isMissingPermissions = modalStatus === modalStatusType.missingPermissions

        const showAudioWave = isIdle || isRecording
        const showEmailForm = (isUploading || isUploaded) && !emailSubmitted

        if (!showModal) {
            return null
        }

        if (isMissingPermissions) {
            return <ModalContainer>
                <div className="rejected-permission-block">
                    <p>{t('rejectedMicAudioFeedback')}</p>
                </div>
            </ModalContainer>
        }

        if (!stream && (isIdle || isRecording)) {
            return <ModalContainer>
                <p>{t('acceptMicAudioFeedback')}</p>
            </ModalContainer>
        }


        let primaryPhrase = t('defaultPhraseAudioFeedback')
        if (isRecording) {
            primaryPhrase = t('recordingPhraseAudioFeedback')
        } else if (isUploaded) {
            primaryPhrase = t('uploadedPhraseAudioFeedback')
        } else if (isUploading) {
            primaryPhrase = t('uploadingPhraseAudioFeedback')
        }

        return <ModalContainer>
            <div className="record-block">
                <p>{!showEmailForm && primaryPhrase}</p>
                {!showEmailForm && <MainButton/>}
                {isRecording && <p className="countdown">{formatCountdown(remainingRecordingTime)}</p>}
                {isIdle && <MicrophoneSelector/>}
                {showAudioWave && <Audiowave microphoneId={selectedMicrophoneId}/>}
                {showEmailForm && <EmailForm/>}
            </div>
        </ModalContainer>
    }
}

const mapStateToProps = ({modalStatus, modalStatusType, selectedMicrophoneId, emailSubmitted, uploadProgress, remainingRecordingTime}) => ({
    remainingRecordingTime: remainingRecordingTime,
    modalStatus: modalStatus,
    emailSubmitted: emailSubmitted,
    uploadProgress: uploadProgress,
    modalStatusType: modalStatusType,
    selectedMicrophoneId: selectedMicrophoneId,
})

export default connect(mapStateToProps)(Modal)