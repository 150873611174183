import React from 'react'
import {Route, Switch} from 'react-router-dom'
import Widget from '../components/Widget'

const MainRoutes = () => {
    return (
        <Switch>
            <Route exact path='/widget' component={Widget}/>
        </Switch>
    )
}


export default MainRoutes
