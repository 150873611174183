import React from 'react'
import _ from 'lodash'

const Button = props => {
    let className

    if (props.primary) {
        className = 'btn-info'
    } else if (props.success) {
        className = 'btn-success'
    } else if (props.delete) {
        className = 'btn-outline-danger'
    } else if (props.outlineprimary) {
        className = 'btn-outline-info'
    } else {
        className = 'btn-secondary'
    }

    const buttonProps = _.omit(props, ['primary', 'secondary', 'success', 'delete','outlineprimary'])

    return (
        <button {...buttonProps} className={`btn ${className} ${props.className ? props.className : ''}`}>
            {props.children}
        </button>
    )
}

export default Button