import React, {Component} from 'react'
import queryString from 'query-string'
import Modal from '../Modal'
import {connect} from 'react-redux'
import actions from '../../actions/types'
import './index.css'

const OpenCloseModalButton = ({openModal, closeModal, modalIsOpen}) => {
    const actionFunction = modalIsOpen ? closeModal : openModal

    const spanId = modalIsOpen ? 'close' : 'open'

    return <div className="brutal-widget" onClick={actionFunction}>
        <span id={spanId}/>
    </div>
}

class Widget extends Component {
    state = {
        stream: null
    }

    constructor(props) {
        super(props)
        const appId = queryString.parse(this.props.location.search).appId
        props.dispatch({
            type: actions.SET_APP_ID,
            payload: appId,
        })
    }

    stopStream = () => {
        const {stream} = this.state

        if (stream) {
            stream.getTracks().forEach(track => track.stop())
        }

        this.setState({
            stream: null,
        })
    }

    requestStream = async () => {
        const microphoneId = this.props.selectedMicrophoneId
        const {modalStatus, modalStatusType, dispatch} = this.props

        try {
            const stream = await navigator.mediaDevices.getUserMedia({audio: {deviceId: microphoneId}})
            this.setState({
                stream: stream,
            })
            if (modalStatus === modalStatusType.missingPermissions) {
                dispatch({
                    type: actions.SET_MODAL_STATUS,
                    payload: modalStatusType.idle,
                })
            }
        } catch (error) {
            console.error(error)
            dispatch({
                type: actions.SET_MODAL_STATUS,
                payload: modalStatusType.missingPermissions,
            })
        }
    }

    openModal = async () => {
        if (!this.state.stream) {
            this.requestStream().catch(console.error)
        }
        this.props.dispatch({
            type: actions.TOGGLE_SHOW_MODAL,
        })
    }

    async componentDidUpdate(previousProps) {
        const previousMicrophoneId = previousProps.selectedMicrophoneId
        const newMicrophoneId = this.props.selectedMicrophoneId

        if (previousMicrophoneId !== newMicrophoneId && this.state.stream) {
            this.stopStream()
            this.requestStream().catch(console.error)
        }
    }

    closeModal = () => {
        const {dispatch, modalStatus, modalStatusType} = this.props

        if (modalStatus === modalStatusType.idle) {
            this.stopStream()
        }

        dispatch({
            type: actions.TOGGLE_SHOW_MODAL,
        })
    }

    MainModal = ({clientId}) => <Modal
        showModal={this.props.showModal}
        stream={this.state.stream}
        stopStream={this.stopStream}
        clientId={clientId}
    />

    render() {
        const {appId, showModal} = this.props

        if (!appId) {
            return <div className="brutal-container">
                <h1>appId parameter is mandatory.</h1>
            </div>
        }

        const {openModal, closeModal, MainModal} = this

        return <div className="brutal-container">
            <MainModal clientId={appId}/>
            <OpenCloseModalButton
                openModal={openModal}
                closeModal={closeModal}
                modalIsOpen={showModal}
            />
        </div>
    }
}

const mapStateToProps = ({showModal, appId, modalStatus, modalStatusType, selectedMicrophoneId}) => ({
    showModal: showModal,
    selectedMicrophoneId: selectedMicrophoneId,
    modalStatus,
    modalStatusType,
    appId,
})

export default connect(mapStateToProps)(Widget)
