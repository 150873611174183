import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import i18n from './services/i18n'
import {I18nextProvider} from 'react-i18next'
import MainRouter from './router/MainRouter'

import store from './store'

const root = (
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <BrowserRouter>
                <MainRouter/>
            </BrowserRouter>
        </I18nextProvider>
    </Provider>
)

ReactDOM.render(root, document.getElementById('root'))
serviceWorker.unregister();
