import types from './types'
import * as api from '../services/api'

const startRecording = ({onRecordingCountdown}) => (dispatch, getState) => {
    dispatch({
        type: types.SET_MODAL_STATUS,
        payload: getState().modalStatusType.recording,
    })

    fetchUploadURL(dispatch, getState)

    return startCountdownTimer({onRecordingCountdown})(dispatch, getState)
}

const fetchUploadURL = (dispatch, getState) => {
    const fetchUploadURLWithRetries = retries => api
        .getUploadURL({clientId: getState().appId})
        .then(({uploadURL, testExternalId}) => {
            dispatch({
                type: types.SET_TEST_DETAILS,
                payload: {uploadURL, testExternalId}
            })
        })
        .catch(error => {
            console.error(`Error. ${retries} attempts left`, error)
            if (retries > 0) {
                console.error('Retrying call...')
                setTimeout(() => fetchUploadURLWithRetries(retries - 1), 1000)
            }
        })

    fetchUploadURLWithRetries(3)
}

let countdownTimer = null

const startCountdownTimer = ({onRecordingCountdown}) => (dispatch, getState) => {
    dispatch({
        type: types.RESET_COUNTDOWN_TIMER
    })
    clearInterval(countdownTimer)
    countdownTimer = setInterval(() => {
        dispatch({
            type: types.COUNTDOWN_TICK
        })

        const {remainingRecordingTime} = getState()

        if (remainingRecordingTime <= 0) {
            clearInterval(countdownTimer)
            if (onRecordingCountdown) {
                onRecordingCountdown()
            }
        }
    }, 1000)

}

const stopRecording = (dispatch, getStatus) => {
    clearInterval(countdownTimer)

    dispatch({
        type: types.RESET_COUNTDOWN_TIMER
    })

    dispatch({
        type: types.SET_MODAL_STATUS,
        payload: getStatus().modalStatusType.uploading,
    })
}

const uploadBlob = ({blob}) => (dispatch, getState) => {
    api.uploadBlob({
        onProgress: p => dispatch({type: types.SET_UPLOAD_PROGRESS, payload: parseInt(p.percent)}),
        onError: e => console.error('Error uploading blob', e),
        uploadURL: getState().uploadURL,
        blob: blob
    }).catch(console.error)
}

const submitEmail = email => (dispatch, getState) => {
    dispatch({
        type: types.SUBMIT_TESTER_EMAIL,
        payload: email
    })

    api.submitTesterEmail({
        email: email,
        clientId: getState().appId,
        testExternalId: getState().testExternalId,
    }).catch(console.error)
}


export default {
    startRecording: startRecording,
    stopRecording: stopRecording,
    uploadBlob: uploadBlob,
    submitEmail: submitEmail,
}

