import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translations from './brutal-translations.json'

i18n.use(LanguageDetector).init({
    resources: translations,
    fallbackLng: 'en',
    debug: true,
    ns: ['translation'],
    defaultNS: 'translation',
    keySeparator: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ','
    },

    react: {
        wait: true
    }
});

const t = i18n.t.bind(i18n)

export default i18n
export {t}