import {createStore, applyMiddleware} from 'redux'
import rootReducer from '../reducers'
import thunk from 'redux-thunk'
import {createLogger} from 'redux-logger'
import actions from '../actions'

const middlewares = [thunk]

if (process.env.NODE_ENV !== 'production') {
    const logger = createLogger()

    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
        middlewares.push(window.__REDUX_DEVTOOLS_EXTENSION__)
    }

    middlewares.push(logger)
}

const store = createStore(
    rootReducer,
    applyMiddleware(...middlewares)
);

export default store