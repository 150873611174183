import actionTypes from '../actions/types'

const modalStatusType = {
    idle: 'idle',
    missingPermissions: 'no-permissions',
    recording: 'recording',
    uploading: 'uploading',
    done: 'done',
}

const DEFAULT_RECORDING_COUNTDOWN = 3 * 60

const initialState = {
    modalStatus: modalStatusType.idle,
    modalStatusType: modalStatusType,
    showModal: false,
    emailSubmitted: false,
    selectedMicrophoneId: 'default',
    uploadProgress: 0,
    testExternalId: null,
    uploadURL: null,
    appId: null,
    remainingRecordingTime: DEFAULT_RECORDING_COUNTDOWN,
}

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MODAL_STATUS: {
            if (!Object.values(modalStatusType).includes(action.payload)) {
                return state
            }

            return {
                ...state,
                modalStatus: action.payload,
            }
        }
        case actionTypes.TOGGLE_SHOW_MODAL: {
            return {
                ...state,
                showModal: !state.showModal,
            }
        }
        case actionTypes.SET_APP_ID: {
            return {
                ...state,
                appId: action.payload,
            }
        }
        case actionTypes.SET_UPLOAD_PROGRESS: {
            return {
                ...state,
                uploadProgress: action.payload,
            }
        }
        case actionTypes.COUNTDOWN_TICK: {
            return {
                ...state,
                remainingRecordingTime: state.remainingRecordingTime - 1,
            }
        }
        case actionTypes.RESET_COUNTDOWN_TIMER: {
            return {
                ...state,
                remainingRecordingTime: DEFAULT_RECORDING_COUNTDOWN,
            }
        }
        case actionTypes.SET_SELECTED_MICROPHONE: {
            return {
                ...state,
                selectedMicrophoneId: action.payload,
            }
        }
        case actionTypes.SET_TEST_DETAILS: {
            return {
                ...state,
                uploadURL: action.payload.uploadURL,
                testExternalId: action.payload.testExternalId,
            }
        }
        case actionTypes.SUBMIT_TESTER_EMAIL: {
            return {
                ...state,
               emailSubmitted: true,
            }
        }
        default: {
            return state;
        }
    }
}

export default rootReducer
