import React, {Component} from 'react'
import {connect} from 'react-redux'
import actionTypes from '../../actions/types'

class MicrophoneSelector extends Component {
    state = {
        microphones: []
    }

    async componentDidMount() {
        const microphones = (await navigator.mediaDevices.enumerateDevices())
            .filter(d => d.kind === 'audioinput')

        this.setState({
            microphones
        })
    }

    onSelectMicrophone = event => {
        this.props.dispatch({
            type: actionTypes.SET_SELECTED_MICROPHONE,
            payload: event.target.value,
        })
    }

    render() {
        const {selectedMicrophoneId} = this.props

        const Options = this.state.microphones.map((mic, i) =>
            <option key={mic.deviceId}
                    value={mic.deviceId}>
                {mic.label ? mic.label : `Microphone ${i + 1}`}
            </option>)

        return <select value={selectedMicrophoneId} onChange={this.onSelectMicrophone}>
            {Options}
        </select>
    }
}

const mapStateToProps = ({selectedMicrophoneId}) => ({selectedMicrophoneId})

export default connect(mapStateToProps)(MicrophoneSelector)